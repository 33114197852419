<template>
  <div>
    <Header />
    <div class="m-conbbox" style="padding-top: 80px;">
      <MyBanners :navIndex="6" />
      <div class="wp1200">
        <ul class="ul-listb1">
          <li class="g-collectb1" v-for="(item, index) in list" :key="index">
            <div class="con">
              <div class="top">
                <div class="name">{{ item.title }}</div>
                <div class="detail" @click="toDetail(item.id,item.pid)">查看详情</div>
              </div>
              <div class="center">
                <!-- <div class="tags">
                  <span
                    class="tag"
                    style="cursor: pointer;"
                    v-for="(item1, index1) in item.forumClass"
                    :key="index1"
                    @click="toForumList(item.pid)"
                  >{{ index1 == item.forumClass.length - 1 ? item1 : item1 + '&gt;' }}</span>
                </div> -->
                <div class="time">发帖时间：{{ item.createTime }}</div>
              </div>
              <div class="bot">
                <div class="desc" v-html="item.postsContent"></div>
                <div class="opt">
                  <div class="btn" v-if="item.like==false" @click="like(item.id,1,index)">
                    <div class="icn">
                      <img src="@/assets/images/icon_fav.png" alt />
                    </div>
                    <div class="p">点赞</div>
                  </div>
                  <div class="btn" v-else @click="like(item.id,0,index)">
                    <div class="icn">
                      <img src="@/assets/images/icon_faved.png" alt />
                    </div>
                    <div class="p">取消点赞</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 内容为空 -->
        <ContentNull style="display: none;" />
        <div class="pagination-box style2" v-loading.fullscreen.lock="false">
          <el-pagination
            :total="total"
            :page-size="params.pageSize"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import MyBanners from '@/components/MyBanners.vue';
import ContentNull from '@/components/ContentNull.vue';
import Api from '@/request/api';
export default {
  components: {
    Header,
    Footer,
    MyBanners,
    ContentNull
  },
  data() {
    return {
      params: {
        pageNo: 1,
        pageSize: 10,
        order: '',
        sort: 0,
		type:2
      },
      total: 0,
      list: []
    }
  },
  created() {
    this.getList()
  },
  watch: {
    $route(v) {
      if (v.path == '/mylike') {
        this.getList()
      }
    }
  },
  methods: {
    getList() {
      Api.myLike(this.params).then(res => {
        console.log(res)
        for (let i = 0; i < res.records.length; i++) {
          res.records[i].like = true
        }
        this.list = res.records
        this.total = res.total
      })
    },
    handleCurrentChange(v) {
      this.params.pageNo = v
      this.getList()
    },
    // 点赞
    like(id,like,index) {
      if (like) {
      	Api.forumLike({ postsId: id,type:2 }).then(res => {
      		this.list[index].like = like
      		this.list[index].likeNum -= 1
      	})
      } else {
      	Api.forumLike({ postsId: id,type:2 }).then(res => {
              this.list[index].like = like
              this.list[index].likeNum += 1
      	})
      }
    },
    // 跳转详情
    toDetail(id,pid) {
      this.$router.push({ path: '/forumdetail', query: { id: id,pid: pid } })
    },
    toForumList(pid) {
      this.$router.push({ path: '/forumlist', query: { pid: pid } })
    },
  },


}
</script>
<style>
img {
  width: unset;
}
</style>
<style scoped>
@import "~@/assets/css/m-bl.css";
.wp1200{
  padding-top: 20px;
}
.ul-listb1{
  margin-bottom: 45px;
}
.pagination-box.style2 {
  text-align: center;
  margin-bottom: 60px;
}
@media only screen and (max-width: 750px) {
  .wp1200{
    padding-top: .3rem;
  }
  .ul-listb1{
    margin-bottom: .4rem;
  }
}

</style>